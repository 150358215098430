import { useContext, useState } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { useCustomRouter } from "../hooks/use_custom_router";
import { Image } from "./common/scaled-next-image";
import { EventPane } from "./core/event_pane";

export type CollectionGridItemProps = {
    imageSrc: string;
    hoverImageSrc?: string;
    route: string;
    caption: string;
};

export function CollectionGridItem({imageSrc,hoverImageSrc,route,caption}:CollectionGridItemProps)
{
    const [showImage,setShowImage] = useState(false);

    const { mobileDetect: { widthCutoff: mobileCutoff } } = useContext(LayoutStateContext);

    const router = useCustomRouter();

    const clickHandler = () => {
        router.push(route);
    };

    return (
        <>
            <div className={"container"+(showImage?" show":"")}>
                <Image src={imageSrc} layout="fill" onLoadingComplete={() => setShowImage(true)} />
                {hoverImageSrc?(
                    <div className="overlay">
                        <div className="inner">
                            <Image src={hoverImageSrc} layout="fill" />
                        </div>
                    </div>
                ):null}
                <div className="caption">{caption}</div>
                <div className="mobile-overlay"></div>
                <EventPane onClick={clickHandler} />
            </div>
            <div className="mobile-caption">
                {caption}
            </div>
            <style jsx>{`
            .container {
                width: 100%;
                padding: 50%;
                cursor: pointer;
                position: relative;
                --duration: 0.7s;
                visibility: hidden;
                opacity: 0;
                border: 1rem solid #e6e6e6;
            }

            .caption {
                position: absolute;
                left: 17rem;
                top: 17rem;
                --color: #999;
                color: var(--color);
                /* background-color: #aaa; */
                padding: 3rem 12rem;
                border-radius: 20rem;
                font-size: 12rem;
                border: 1rem solid var(--color);
                font-weight: 600;
            }

            .container.hover .caption {
                background-color: #444;
                color: #FFF;
            }

            .container.hover {
                border: 1rem solid #ccc;
            }

            .container.show {
                transition: visibility var(--duration),opacity var(--duration);
                visibility: visible;
                opacity: 1;
            }

            .overlay {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            .overlay .inner {
                position: relative;
                width: 100%;
                height: 100%;
            }

            .mobile-overlay {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.075);
                visibility: hidden;
                opacity: 0;
                --duration: var(--touch-duration);
                transition: opacity var(--duration),visibility var(--duration);
            }

            .container.hover .overlay {
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }

            .container.touchdown .mobile-overlay {
                visibility: visible;
                opacity: 1;
            }

            .mobile-caption {
                text-align: center;
                font-size: 11rem;
                padding: 4rem 0rem;
                color: #444;
                display: none;
            }

            @media screen and (max-width: ${mobileCutoff}px) {
                .container {
                    border: 1rem solid #eee;
                }

                .mobile-caption {
                    display: block;
                }

                .caption {
                    display: none;
                }
            }
            `}</style>
        </>
    );
}