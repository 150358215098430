import Head from 'next/head'
import { useContext, useEffect, useRef, useState } from 'react'
import Banner, { TBannerLayout } from '../components/banner';
import { CollectionGridItem } from '../components/collection_grid_item';
import { ScrollAnimation } from '../components/core/scroll_animation';
import { Grid } from '../components/core/grid';
import { WindowSizeContext } from '../contexts/window_size'
import { LayoutStateContext } from '../contexts/layout_state';
import { prebuildData } from '../lib/prebuild-data';
import { useCustomRouter } from '../hooks/use_custom_router';
import { getInjectableHeaderTags } from '../components/layout';
import { useCssStateManager } from '../hooks/use_css_state_manager';
import { GlobalTheme } from "../lib/shared/options/types";
import { useSmartState } from '../hooks/use_smart_state';
//aspectRatio={1.382882883} 

type HomeAnnouncementBoardProps = {
    data: GlobalTheme["homeAnnouncementBoard"];
};

function HomeAnnouncementBoard({data}: HomeAnnouncementBoardProps) {
    const cssStateManager = useCssStateManager(["home-announcement-board"])

    if (data.lines.length===0) return null;

    const { lines,fontSize,lineHeight,textColor,verticalPadding } = data;

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                {lines.map((line,index) => (
                    <div key={index} dangerouslySetInnerHTML={{__html: line}}></div>
                ))}
            </div>
            <style jsx>{`
            .home-announcement-board {
                color: ${textColor};
                text-align: center;
                font-size: ${fontSize}rem;
                padding: ${verticalPadding}rem 0rem;
                line-height: ${lineHeight}rem;
            }
            `}</style>
        </>
    );
}


export default function Home() 
{
    const router = useCustomRouter();
    const shiftLeft = -2;
    const windowSize = useContext(WindowSizeContext);
    const windowSizeWidthRef = useRef(windowSize.width);
    windowSizeWidthRef.current = windowSize.width;
    const { page,scalePixelToRem,scaleFactorDetectUseEffect } = useContext(LayoutStateContext);
    const [columns,setColumns] = useState(3);
    const bannerHeight = useSmartState(444);

    useEffect(() => 
    {
        const columnsCount = (windowSize.width<=750)?2:3;
        setColumns(columnsCount);
        // bannerHeight.value = (scalePixelToRem(windowSize.width)/columnsCount)*0.97;
    },[`${windowSize.height}-${windowSize.width}`]);

    scaleFactorDetectUseEffect((scalePixelToRem) => {
      const columnsCount = (windowSizeWidthRef.current<=750)?2:3;
      bannerHeight.value = (scalePixelToRem(windowSizeWidthRef.current)/columnsCount)*0.97;
    },[]);


    const bannerClickHandler = () => {
        const pageRoute = prebuildData.getProductPageRoute(prebuildData.getProductAliasStub("caliburnA2ReplacementPods").id);        
        router.push(pageRoute);
    };

    const gridImages = prebuildData.getHomePageGridImages();

    useEffect(() => {
      return router.beforePushSubscribe((cb) => {
          page.scrollToTopWithCallback(cb);
          return true;
      });
    },[]);

    const { indexMeta } = prebuildData.getGlobalConfig();
    const { homeAnnouncementBoard } = prebuildData.getActiveTheme();

    return (
      <>
        <Head>
          {getInjectableHeaderTags(indexMeta.title,indexMeta.description,"")}
        </Head>
        <div className="container">
          <HomeAnnouncementBoard data={homeAnnouncementBoard} />
          <Banner 
              src={prebuildData.bustCache("/images/banner2.jpg")} 
              aspectRatio={825/444}
              height={bannerHeight.value /* 444 */} 
              texts={[
                  {
                      color: "#000000",
                      family: "Open Sans",
                      left: 63+shiftLeft,
                      top: 10,
                      size: 8,
                      weight: 600,
                      text: "Always Satisfying."
                  },{
                      color: "#000000",
                      family: "Open Sans",
                      left: 66+shiftLeft,
                      top: 22,
                      size: 6,
                      weight: 500,
                      text: "Never compromising."
                  },{
                        color: "#4049ff",
                      family: "Open Sans",
                      left: 77+shiftLeft,
                      top: 32,
                      size: 5,
                      weight: 500,
                      text: "Learn more >",
                      url: "https://www.tbdliquids.com"
                  }
              ]}
              layout={TBannerLayout.Center}
              onClick={bannerClickHandler}
          />
          <div className="under-banner-spacer"></div>
          <div className="grid-frame">
            <Grid square={false} columns={columns} horizontalCellSpacing={10} verticalCellSpacing={10} scrollAnimation scrollAnimationThreshold={0.55}>
              {gridImages.map(({route,caption,image,imageHover},index) => <CollectionGridItem key={route} route={route} caption={caption} imageSrc={prebuildData.bustCache(image)} hoverImageSrc={prebuildData.bustCache(imageHover)} />)}
            </Grid>
          </div>
        </div>
        <style jsx>{`
        .under-banner-spacer {
          height: 18rem;
        }
        .grid-frame {
          padding: 0rem 10rem;
          margin-bottom: 50rem;
        }
        `}</style>
      </>
    )
}
